<template>
  <b-modal
      id="1"
      :title="capitalize($t(title))"
      v-model="isOpenLocal"
      size="sm"
      scrollable
      :hide-footer="hideFooter"
      :ok-title="capitalize($t('validate'))"
      :cancel-title="capitalize($t('cancel'))"
      @hide="tryCloseModal"
      @hidden="handleCancel"
      @ok="handleOk"
  >
    <validation-observer
        ref="observerRef"
        #default="{handleSubmit}">
      <b-form @submit.prevent="handleSubmit(submitValidatedLocal)">

        <b-row>
          <!--      FirstName-->
          <b-col cols="12">
            <field
                :name="'firstName'"
                :rules="'required'"
                :model.sync="newEmployeeLocal.firstName"
            />
          </b-col>

          <!--      Name-->
          <b-col cols="12">
            <field
                :name="'name'"
                :rules="'required'"
                :model.sync="newEmployeeLocal.name"
            />
          </b-col>

          <!--      Email-->
          <b-col cols="12">
            <field
                :name="'email'"
                :rules="'required|email'"
                :model.sync="newEmployeeLocal.email"
            />
          </b-col>

          <!--      Role-->
          <b-col cols="12">
            <field-select
                :model.sync="newEmployeeLocal.roles"
                :multiple="true"
                :name="'role'"
                :options="roleOptions"
                :reduce="'value'"
                :isRequired="true"
            />
          </b-col>
        </b-row>


        <button ref="submitFormRef"
                class="d-none"></button>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { ref, computed, watch, onMounted } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import { capitalize } from '@/utils/filter'

import useAPI from '../../utils/useAPI'
import Field from '@/components/input/Input'
import FieldSelect from '@/components/input/Select'
import store from '@/store'

export default {
  components: {
    ValidationObserver,
    Field,
    FieldSelect
  },
  props: {
    newEmployee: {
      type: Object,
      default: () => {}
    },
    title: {
      type: String,
      default: ''
    },
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const isOpenLocal = ref(false)
    const newEmployeeLocal = ref(props.newEmployee)
    const submitFormRef = ref(null)
    const observerRef = ref(null)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { currentBreakPoint } = useAPI()

    const hideFooter = computed(() => {
      return !['xs', 'sm'].includes(currentBreakPoint.value)
    })

    const roleOptions = computed(() => {
      return store.getters['user/getRoleOptions']
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(isOpenLocal, (val) => {
      emit('update:isOpen', val)
    })
    watch(newEmployeeLocal, (val) => {
      emit('update:newEmployee', val)
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const tryCloseModal = (modalEvent) => {
      if (modalEvent.trigger == 'backdrop') {
        // Click outside
        modalEvent.preventDefault()
        submitFormRef.value.click()
      }
    }

    const submitValidatedLocal = () => {
      emit('submitValidated')
    }

    const handleOk = async (bvModalEvent) => {
      bvModalEvent.preventDefault()

      const isValid = await observerRef.value.validate()
      if (isValid) {
        submitValidatedLocal()
      }
    }

    const handleCancel = () => {}

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    newEmployeeLocal.value.roles = ['ROLE_USER']

    return {
      // Components
      capitalize,

      // Data
      isOpenLocal,
      newEmployeeLocal,
      submitFormRef,
      observerRef,

      // Computed
      roleOptions,
      hideFooter,

      // Methods
      tryCloseModal,
      submitValidatedLocal,
      handleOk,
      handleCancel,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {
    newEmployee: function (val) {
      this.newEmployeeLocal = val
    },
    isOpen: function (val) {
      this.isOpenLocal = val
    }
  },
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>