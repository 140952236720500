import { ref, computed, watch, onMounted } from '@vue/composition-api'
import { useEmployees } from './useEmployees'
import { capitalize } from '@/utils/filter'

import router from '@/router'
import { getRoute } from '../../../utils/utils'
import useAPI from '../../../utils/useAPI'

export const mixinEmployees = {
  components: {},
  props: {},
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const newEmployee = ref({})
    const newEmployeeModalShow = ref(false)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { users2} = useAPI()

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const {
      fetchUsers2,
    } = useAPI()

    const {
      submitValidatedEmployee,
      removeEmployee,
    } = useEmployees()

    const addEmployee = () => {
      // console.log("addEmployee")
      newEmployeeModalShow.value = true
    }

    const submitValidatedEmployeeLocal = (employee) => {
      submitValidatedEmployee(employee)
        .then(response => {
          newEmployeeModalShow.value = false
          // console.log(response)
        })
    }

    const removeEmployeeLocal = (employee) => {
      removeEmployee(employee)
        .then(response => {
          // router.push({ name: 'Employees' })
        })
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    if (getRoute().params != null && ('employeeId' in getRoute().params)) {
      // fetchUsers2(getRoute().params.employeeId).then(() => {
      //   // fetchForDetails()
      // })
      // fetchUsers2({
      //   filters: [{
      //     groupOperator: 'AND',
      //     group: [
      //       {
      //         comparator: 'equal',
      //         field: 'id',
      //         value: getRoute().params.employeeId
      //       },
      //     ]
      //   }]
      // })
    } else if(getRoute().name != 'AccountSettings') {
      fetchUsers2({
        filters: [{
          sectionLogicOperator: 'AND',
          sectionFilters: [
            {
              logicOperator: "AND",
              operator: 'is_not_empty',
              field: 'invitationCode',
            },
            // {
            //   comparator: 'is_not_empty',
            //   field: 'password',
            // },
            // {
            //   comparator: 'not_equal',
            //   field: 'roles',
            //   value: '[]'
            // }
          ]
        }]
      })



      // fetchUsers2().then(() => {
      //   // fetchForDetails()
      // })
    }



    // fetchUsers()

    return {
      // Components
      capitalize,

      // Data
      newEmployee,
      newEmployeeModalShow,

      // Computed
      users2,

      // Methods
      addEmployee,
      submitValidatedEmployeeLocal,
      removeEmployeeLocal,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {
    deleteEmployeeAlert (employee) {
      this.$bvModal
        .msgBoxConfirm(this.$t('DeleteAlert', { msg: this.$t('theEmployee') }), {
          okVariant: 'danger',
          okTitle: this.capitalize(this.$t('delete')),
          cancelVariant: 'outline-secondary',
          cancelTitle: this.capitalize(this.$t('cancel')),
          centered: true,
        })
        .then(value => {
          if (value == true) {
            this.removeEmployeeLocal(employee)
          }
        })
    }
  },
  mounted () {
  },
  created () {
  }
}